import produce from "immer";
import { EXAM_TYPE_FETCHED, EXAM_TYPE_FETCH_FAILED } from "./type";

const initialState = {
  examTypes: { data: [], isFetched: false },
};

export default produce(function master(state, action = {}) {
  switch (action.type) {
    case EXAM_TYPE_FETCHED: {
      state.examTypes.data = action.data;
      state.examTypes.isFetched = true;
      return;
    }
    case EXAM_TYPE_FETCH_FAILED: {
      state.examTypes.data = [];
      state.examTypes.isFetched = false;
      return;
    }
    default: {
      return state;
    }
  }
}, initialState);
