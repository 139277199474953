export const EXAM_TYPES = [
  {
    id: "midsemesterexam",
    name: "PTS",
  },
  {
    id: "finalsemesterexam",
    name: "PAS",
  },
  {
    id: "finalyearexam",
    name: "PAT",
  },
  {
    id: "finalexam",
    name: "UKT",
  },
  {
    id: "schoolexam",
    name: "US",
  },
  {
    id: "tryout",
    name: "TO",
  },
];
