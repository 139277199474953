import React from "react";

class WelcomePopup extends React.Component {
  render() {
    return (
      <div
        className="alert-alt alert-info alert-icon"
        style={{ borderRadius: 0 }}
      >
        <em className="icon ni ni-help-alt"></em>{" "}
        <strong>
          Edulogy telah berhasil melakukan ujian dengan total 30.000 siswa.
        </strong>
      </div>
    );
  }
}

export default WelcomePopup;
