import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Block({ children, className = "", ...restProps }) {
  return (
    <div className={cx("nk-block", className)} {...restProps}>
      {children}
    </div>
  );
}

Block.Between = ({ size = "md", className = null, children, ...restProps }) => {
  return (
    <div className={cx("nk-block-between-" + size, className)} {...restProps}>
      {children}
    </div>
  );
};

Block.Head = ({ size = "md", children, ...restProps }) => {
  return (
    <div className={"nk-block-head nk-block-head-" + size} {...restProps}>
      {children}
    </div>
  );
};

Block.Head.Content = ({ children, ...restProps }) => {
  return (
    <div className="nk-block-head-content" {...restProps}>
      {children}
    </div>
  );
};

Block.Head.Content.Sub = ({ children, ...restProps }) => {
  return (
    <div className="nk-block-head-sub" {...restProps}>
      {children}
    </div>
  );
};

Block.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};

Block.Between.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Classname
   */
  className: PropTypes.string,
  /**
   * Classname
   */
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
};

Block.Head.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Classname
   */
  className: PropTypes.string,
};

Block.Head.Content.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};

Block.Head.Content.Sub.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};
