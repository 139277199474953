export const RESET = "AKM/WIZARD_FORM/RESET";

export const TYPE_CHANGED = "AKM/WIZARD_FORM/TYPE_CHANGED";

export const CLASS_ADDED = "AKM/WIZARD_FORM/CLASS_ADDED";

export const CLASS_REMOVED = "AKM/WIZARD_FORM/CLASS_REMOVED";

export const STUDENT_ADDED = "AKM/WIZARD_FORM/STUDENT_ADDED";

export const STUDENT_REMOVED = "AKM/WIZARD_FORM/STUDENT_REMOVED";

export const EXAM_ADDED = "AKM/WIZARD_FORM/EXAM_ADDED";

export const EXAM_REMOVED = "AKM/WIZARD_FORM/EXAM_REMOVED";

export const SWITCHED_TO_NEXT = "AKM/WIZARD_FORM/SWITCHED_TO_NEXT";

export const SWITCHED_TO_PREV = "AKM/WIZARD_FORM/SWITCHED_TO_PREV";
