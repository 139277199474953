import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "components/Form/ReactSelectWrapper";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class TypeFilter extends React.Component {
  render() {
    const { examTypes, isLoading, onChange, ...props } = this.props;
    if (isLoading) {
      return (
        <div className="text-center">
          <SpinnerGrow variant="warning" />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Select
          {...props}
          isMulti
          placeholder="Semua Jenis"
          options={examTypes.map((type) => ({
            value: type.id,
            label: type.name,
          }))}
          onChange={(raw) => {
            if (!raw) raw = [];
            const examTypes = raw.map(({ value }) => value);
            onChange(examTypes);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  examTypes: state.master.examTypes.data,
  isLoading: !state.master.examTypes.isFetched,
});

export default connect(mapStateToProps)(TypeFilter);
