import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Page from "components/Page";
import { v4 as uuidv4 } from "uuid";

const AnyRoute = ({
  component: Component,
  layout: Layout = null,
  title,
  ...rest
}) => {
  document.title = title;
  return (
    <Route
      {...rest}
      render={(props) =>
        Layout ? (
          <Layout location={props.location}>
            <Page>
              <Component {...props} key={uuidv4()} />
            </Page>
          </Layout>
        ) : (
          <Component {...props} key={uuidv4()} />
        )
      }
    />
  );
};

AnyRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  title: PropTypes.string.isRequired,
};

export default AnyRoute;
