import axios from "configuredAxios";
import originalAxios from "axios";

const customAxios = originalAxios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

export default {
  getProfileByAccessToken: ({ access_token }) =>
    customAxios
      .get(`v1/profile`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => res.data.data),
};
