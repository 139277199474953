import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function ImageCircle({
  size,
  src,
  alt,
  className = null,
  style = {},
  ...restProps
}) {
  return (
    <img
      className={cx("rounded-circle", className)}
      style={{ width: size, height: size, ...style }}
      src={src}
      alt={alt}
      {...restProps}
    />
  );
}

ImageCircle.propTypes = {
  size: PropTypes.oneOf(PropTypes.string, PropTypes.string).isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
