import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Truncate from "domain/String/Truncate";
import Badge from "components/Badge/Badge";
import ImageCircle from "components/atoms/Image/ImageCircle";
import LinkButton from "components/molecules/Button/LinkButton";

export default function Row({ exam }) {
  return (
    <tr>
      <td className="text-nowrap text-truncate">
        <div className="d-flex">
          <ImageCircle src={exam.school.logo} size={42} />
          <div className="ml-2">
            <span data-tip={exam.title} className="text-secondary">
              {Truncate(exam.title, 40)}
            </span>
            <div>
              <small>{exam.school.name}</small>
            </div>
          </div>
        </div>
      </td>
      <td>
        <Badge
          variant="info"
          className="text-nowrap text-truncate"
          style={{ maxWidth: "100%" }}
          outline
        >
          {exam.formated_type}
        </Badge>
      </td>
      <td className="text-nowrap text-truncate">
        Mulai: {moment(exam.start_at).format("D MMM HH:mm z, YYYY")}
        <br />
        Batas: {moment(exam.finish_at).format("D MMM HH:mm z, YYYY")}
      </td>
      <td className="text-center">
        <LinkButton dim size="sm" to={`/exam-monitoring/${exam.id}`}>
          Lihat Siswa
        </LinkButton>
      </td>
    </tr>
  );
}
