import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// TODO: ini deprecated
const AlertInfo = ({ children, className = "", ...props }) => (
  <div
    {...props}
    className={cx(className, {
      alert: true,
      "alert-primary": Boolean(props.primary),
      "alert-secondary": Boolean(props.secondary),
      "alert-success": Boolean(props.success),
      "alert-info": Boolean(props.info),
      "alert-warning": Boolean(props.warning),
      "alert-danger": Boolean(props.danger),
      "alert-gray": Boolean(props.gray),
      "alert-light": Boolean(props.light),

      "alert-fill": Boolean(props.fill),
      "alert-icon": Boolean(props.icon),
      "alert-pro": Boolean(props.pro),
      "alert-dismissible": Boolean(props.dismissible),
    })}
  >
    {props.icon && <em className={"icon ni ni-" + props.icon}></em>} {children}
  </div>
);

AlertInfo.propTypes = {};

export default AlertInfo;
