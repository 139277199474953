import React from "react";
import { Switch, useHistory } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";
import NoInternet from "components/Error/NoInternet";
import Subscription from "./components/Layouts/Subscription";
import Blank from "./components/Layouts/Blank";

import AuthLegacyPage from "./pages/AuthLegacy";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ExamMonitoring from "./pages/ExamMonitoring";
import ResetScroll from "components/ResetScroll";

const E_404 = lazyDelay(() => import("./pages/E_404"));
const ExamMonitoringDetail = lazyDelay(() =>
  import("./pages/ExamMonitoringDetail")
);
const ExamMonitoringSubmission = lazyDelay(() =>
  import("./pages/ExamMonitoringSubmission")
);
const ExamPrint = lazyDelay(() => import("./pages/ExamPrint"));
const AKMAnalysis = lazyDelay(() => import("./pages/AKMAnalysis"));

function App(props) {
  const history = useHistory();

  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <>
      <React.Suspense fallback={<PageFallback />}>
        <ResetScroll />
        <Switch>
          <AnyRoute
            path="/auth-legacy"
            layout={Blank}
            exact
            component={AuthLegacyPage}
            title="Mengalihkan..."
          />
          <UserRoute
            path="/"
            layout={Subscription}
            exact
            component={ExamMonitoring}
            title="Ujian Lembaga Lain"
          />
          <UserRoute
            path="/exam-monitoring"
            layout={Subscription}
            exact
            component={ExamMonitoring}
            title="Ujian Lembaga Lain"
          />
          <UserRoute
            path="/exam-monitoring/:examId"
            layout={Subscription}
            exact
            component={ExamMonitoringDetail}
            title="Daftar Siswa Ujian Lembaga Lain"
          />
          <UserRoute
            path="/exam-monitoring-submission/:submissionId"
            layout={Blank}
            exact
            component={ExamMonitoringSubmission}
            title="Pengerjaan Siswa di Lembaga Lain"
          />
          <UserRoute
            path="/exam-print/:examId"
            layout={Blank}
            exact
            component={ExamPrint}
            title="Print Ujian"
          />
          <UserRoute
            path="/akm-analytic"
            layout={Blank}
            exact
            component={AKMAnalysis}
            title="Analisis AKM"
          />
          <GuestRoute path="/login" exact component={LoginPage} title="Login" />
          <UserRoute path="/logout" exact component={LogoutPage} title="Logout..." />

          <AnyRoute component={E_404} title="Halaman Tidak Ditemukan" />
        </Switch>
      </React.Suspense>
    </>
  );
}

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <App {...props} />
  </ErrorBoundary>
);
