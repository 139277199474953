import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncSelect from 'react-select/async';

/**
 * ReactSelect defaultValue property itu tidak seperti defaultValue
 * input biasa namun harus menyertakan labelnya juga. Itu behavior
 * yang aneh dan bikin ribet. Oleh karena itu wrapper ini mempermudah
 * penggunaan properti defaultValue.
 *
 * Sebelumnya:
 *   defaultValue={[
 *    {value: "web", label: "Web"},
 *    {value: "android", label: "Android"},
 *   ]}
 *
 * Setelahnya:
 *   defaultValue={["web", "android"]}
 */
class ReactSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { defaultValue = null, isAsync, ...props } = this.props;
    if (this.props.isMulti && Array.isArray(defaultValue)) {
      defaultValue = defaultValue.map(
        (value) =>
          this.props.options.filter((option) => option.value === value)[0]
      );
    }

    let Component;
    if (isAsync) {
      Component = AsyncSelect;
    } else {
      Component = Select;
    }

    return <Component {...props} defaultValue={defaultValue} />;
  }
}

ReactSelectWrapper.propTypes = {};

export default ReactSelectWrapper;
