import { AUTH_LEGACY_LOGGED_IN, USER_LOGGED_IN, USER_LOGGED_OUT } from "./type";

function auth(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.auth;
    case USER_LOGGED_OUT:
      return {};
    case AUTH_LEGACY_LOGGED_IN:
      return action.auth;
    default:
      return state;
  }
}

export default auth;
