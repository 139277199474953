import React from "react";
import PropTypes from "prop-types";

const EmptyRow = ({
  colSpan,
  message = "Tidak ada data untuk ditampilkan.",
}) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      {message}
    </td>
  </tr>
);

export default EmptyRow;
