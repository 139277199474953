import { createSuccessRest } from "utils/testing";

const EXAMS = {
  total_pages: 1,
  current_page: 1,
  exams: [
    {
      id: 1,
      title: "Pemantapan untuk UTBK",
      start_at: "2017-08-05T03:34:59.000Z",
      finish_at: "2017-08-05T03:34:59.000Z",
      school: {
        id: 1,
        name: "SMK YSB Suryalaya",
        logo:
          "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      },
      type: "tryout",
      formated_type: "Tryout",
    },
    {
      id: 2,
      title: "Pemantapan untuk UTBK Gelombang 2",
      start_at: "2017-08-05T03:34:59.000Z",
      finish_at: "2017-08-05T03:34:59.000Z",
      school: {
        id: 2,
        name: "SMK YSB Suryalaya",
        logo:
          "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      },
      type: "tryout",
      formated_type: "Tryout",
    },
    {
      id: 3,
      title: "Pemantapan untuk UTBK Gelombang 3",
      start_at: "2017-08-05T03:34:59.000Z",
      finish_at: "2017-08-05T03:34:59.000Z",
      school: {
        id: 3,
        name: "SMK YSB Suryalaya",
        logo:
          "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      },
      type: "tryout",
      formated_type: "Tryout",
    },
  ],
};

const MEMBERS = {
  exam: {
    id: 1,
    title: "Pemantapan untuk UTBK",
  },
  members: [
    {
      id: 1,
      photo:
        "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      code: "1010115221",
      nis: "1010115221",
      name: "Faisal",
      final_score: "100.00",
      total_submissions: 1,
    },
    {
      id: 2,
      photo:
        "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      code: "1010115222",
      nis: "1010115222",
      name: "Yayan",
      final_score: "80.25",
      total_submissions: 1,
    },
    {
      id: 3,
      photo:
        "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
      code: "1010115223",
      nis: "1010115223",
      name: "Raka",
      final_score: "00.00",
      total_submissions: 0,
    },
  ],
};

const SUBMISSIONS = {
  exam: {
    id: 1,
    title: "Pemantapan untuk UTBK",
    submission_viewable: false,
  },
  student: {
    id: 1,
    name: "Faisal",
    photo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
  },
  submissions: [
    {
      id: 1,
      start_at: "2017-08-05T03:34:59.000Z",
      finish_at: "2017-08-05T03:34:59.000Z",
      total_gained_points: "50.00",
      total_points: "100.00",
    },
    {
      id: 2,
      start_at: "2017-08-05T03:34:59.000Z",
      finish_at: "2017-08-05T03:34:59.000Z",
      total_gained_points: "00.00",
      total_points: "100.00",
    },
  ],
};

const SUBMISSION = {
  student: {
    id: 1,
    name: "faisal",
    photo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
  },
  setting: {
    formated_security_mode: "Buka Buku",
    random_question: true,
    random_answer: true,
    show_score: true,
    show_submission: true,
    show_discussion: true,
    security_mode: "unlock",
  },
  total_remaining_durations: 0,
  status: "finished",
  formated_status: "Selesai",
  id: 73007,
  started_at: "2021-02-04T04:13:24.000Z",
  finished_at: null,
  total_durations: 480,
  total_points: "135.00",
  total_gained_points: "0.00",
  total_questions: 20,
  total_corrects: 0,
  total_incorrects: 0,
  total_filleds: 0,
  total_empties: 20,
  grade: "XII",
  semester: "odd",
  school_exam_id: 47,
  created_at: "2021-02-04T04:13:24.000Z",
  updated_at: "2021-02-04T04:13:35.000Z",
  exam: {
    id: 47,
    title: "Pengetahuan Edulogy",
    description:
      "Uji pengetahuanmu mengenai edulogy dan dapatkan lemoneva sari lemon asli dari pohonnya, minat? hub: 087825720207",
    instruction: null,
  },
  sections: [
    {
      id: 79195,
      title: "Pengetahuan Umum",
      description: null,
      total_points: "43.00",
      total_gained_points: "0.00",
      total_questions: 9,
      total_corrects: 0,
      total_incorrects: 0,
      total_filleds: 0,
      total_empties: 9,
      questions: [
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086085,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Benarkah edulogy memberikan layanan internet bagi penggunanya?&lt;/p&gt;",
          multichoice: {
            id: 2049261,
            content:
              "&lt;p&gt;Benarkah edulogy memberikan layanan internet bagi penggunanya?&lt;/p&gt;",
            answers: [
              {
                id: 9402489,
                content:
                  "&lt;p&gt;Iya edulogy memberikan layanan internet gratis bagi seluruh penggunanya&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402490,
                content:
                  "&lt;p&gt;Bagi pengguna yang menginginkan layanan internet dan lokasi nya tercover dengan edulogy maka edulogy bisa memberikan dukungan layanan internet cepat, minat hub: 087825720207&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402491,
                content:
                  "&lt;p&gt;Tidak, edulogy tidak memberikan layanan internet bagi penggunanya&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [
            {
              id: 11,
              type: "image",
              formated_type: "Gambar",
              name: "dodol-garut.jpg",
              extension: "jpg",
              size: 13174,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/discussion/2021/01/20/dodol-garut.jpg",
            },
            {
              id: 12,
              type: "audio",
              formated_type: "Suara",
              name: "file-example-mp3-700kb.mp3",
              extension: "mp3",
              size: 764176,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/discussion/2021/01/20/file-example-mp3-700kb.mp3",
            },
            {
              id: 13,
              type: "video",
              formated_type: "Video",
              name: "samplevideo-1280x720-1mb.mp4",
              extension: "mp4",
              size: 1055736,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/discussion/2021/01/20/samplevideo-1280x720-1mb.mp4",
            },
            {
              id: 14,
              type: "text",
              formated_type: "Teks",
              title: null,
              content: "&lt;p&gt;Lorem ipsum dolor simet amet&lt;/p&gt;",
            },
            {
              id: 15,
              type: "text",
              formated_type: "Teks",
              title: null,
              content:
                "&lt;p&gt;Lorem ipsum dolor simet amet. Lorem ipsum dolor simet amet. Lorem ipsum dolor simet amet.&lt;/p&gt;",
            },
          ],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086086,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;&lt;span class=&quot;math-tex&quot;&gt;\\(x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}\\)&lt;/span&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Pilihlah logo edulogy&lt;/p&gt;",
          multichoice: {
            id: 2049262,
            content:
              "&lt;p&gt;&lt;span class=&quot;math-tex&quot;&gt;\\(x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}\\)&lt;/span&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Pilihlah logo edulogy&lt;/p&gt;",
            answers: [
              {
                id: 9402492,
                content:
                  "&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;184&quot; src=&quot;https://soedirman.storage.edulogy.id/file/school/exam/question/attachment/2020/04/12/googlelogo-color-272x92dp.png&quot; width=&quot;544&quot; /&gt;&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402493,
                content:
                  "&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;250&quot; src=&quot;https://soedirman.storage.edulogy.id/file/school/exam/question/attachment/2020/04/12/edulogy-square.png&quot; width=&quot;250&quot; /&gt;&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402494,
                content: "&lt;p&gt;0&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402495,
                content:
                  "&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;181&quot; src=&quot;https://soedirman.storage.edulogy.id/file/school/exam/question/attachment/2020/04/12/mat-logo.png&quot; width=&quot;311&quot; /&gt;&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [
            {
              formated_type: "Gambar",
              id: 7072,
              type: "image",
              image: {
                name:
                  "670-446-striker-lao-toyota-kenal-sepak-bola-indonesia-lewat-persib-m.jpg",
                extension: "jpg",
                size: "41658",
                url:
                  "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/attachment/2020/10/01/670-446-striker-lao-toyota-kenal-sepak-bola-indonesia-lewat-persib-m.jpg",
              },
            },
            {
              formated_type: "Audio",
              id: 7073,
              type: "audio",
              audio: {
                name: "file-example-mp3-700kb-copy.mp3",
                extension: "mp3",
                size: "764176",
                url:
                  "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/attachment/2020/10/01/file-example-mp3-700kb-copy.mp3",
              },
            },
            {
              formated_type: "Video",
              id: 7074,
              type: "video",
              video: {
                name: "pexels-videos-2759451.mp4",
                extension: "mp4",
                size: "718553",
                url:
                  "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/attachment/2020/10/01/pexels-videos-2759451.mp4",
              },
            },
          ],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086087,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Sebutkan jumlah siswa di edulogy hingga Maret 2020&lt;/p&gt;",
          multichoice: {
            id: 2049263,
            content:
              "&lt;p&gt;Sebutkan jumlah siswa di edulogy hingga Maret 2020&lt;/p&gt;",
            answers: [
              {
                id: 9402496,
                content: "&lt;p&gt;10000 Siswa&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402497,
                content: "&lt;p&gt;7500 Siswa&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402498,
                content: "&lt;p&gt;35000 Siswa&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402499,
                content: "&lt;p&gt;500 Siswa&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Menjodohkan",
          formated_status: "-",
          formated_scoring_mode: "Wajib Benar Semua",
          formated_difficulty: "Mudah",
          id: 2086088,
          type: "matching",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: "easy",
          scoring_mode: "allornothing",
          content:
            "&lt;p&gt;Jodohkan masing-masing ibukota dengan provinsinya!&lt;/p&gt;",
          multichoice: null,
          multianswer: null,
          essay: null,
          truefalse: null,
          attachments: [],
          discussions: [
            {
              id: 16,
              type: "image",
              formated_type: "Gambar",
              name: "langka-ada-lumba-lumba-berkepala-dua-170620e.jpg",
              extension: "jpg",
              size: 55130,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/discussion/2021/01/21/langka-ada-lumba-lumba-berkepala-dua-170620e.jpg",
            },
            {
              id: 17,
              type: "image",
              formated_type: "Gambar",
              name: "hiu-putih-raksasa-20180817-142153.jpg",
              extension: "jpg",
              size: 39128,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/exam/question/discussion/2021/01/21/hiu-putih-raksasa-20180817-142153.jpg",
            },
          ],
          answers: [
            {
              id: 1234,
              content: "<p>Sumatera Utara</p>",
            },
            {
              id: 1235,
              content: "<p>Maluku</p>",
            },
            {
              id: 1236,
              content: "&lt;p&gt;Sulawesi Selatan&lt;/p&gt;",
            },
            {
              id: 1237,
              content: "&lt;p&gt;Bali&lt;/p&gt;",
            },
            {
              id: 1238,
              content:
                "&lt;p&gt;&amp;nbsp;&lt;/p&gt;\r\n\r\n&lt;p&gt;Bandung&lt;/p&gt;\r\n\r\n&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;218&quot; src=&quot;https://soedirman.storage.dev.edulogy.id/file/school/exam/question/attachment/2021/01/21/hiu-putih-raksasa-20180817-142153.jpg&quot; width=&quot;388&quot; /&gt;&lt;/p&gt;",
            },
          ],
          statements: [
            {
              id: 311,
              content: "&lt;p&gt;Makassar&lt;/p&gt;",
              student_se_sect_q_answer_id: 1236,
            },
            {
              id: 312,
              content: "&lt;p&gt;Denpasar&lt;/p&gt;",
              student_se_sect_q_answer_id: 1237,
            },
            {
              id: 313,
              content:
                "&lt;p&gt;Jawa Barat &lt;span class=&quot;math-tex&quot;&gt;\\(x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}\\)&lt;/span&gt;&lt;/p&gt;",
              student_se_sect_q_answer_id: 1238,
            },
          ],
          marks: [],
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086089,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "3.00",
          difficulty: null,
          scoring_mode: null,
          content: "&lt;p&gt;Alamat web edulogy&lt;/p&gt;",
          multichoice: {
            id: 2049264,
            content: "&lt;p&gt;Alamat web edulogy&lt;/p&gt;",
            answers: [
              {
                id: 9402500,
                content: "&lt;p&gt;https://edulogy&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402501,
                content: "&lt;p&gt;https://edulogy.indonesia&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402502,
                content: "&lt;p&gt;https://edulogy.id&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402503,
                content: "&lt;p&gt;http://edulogy.com&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086090,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Perhatikan gambar berikut dengan seksama!&lt;/p&gt;\r\n\r\n&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;672&quot; src=&quot;https://soedirman.storage.edulogy.id/file/school/exam/question/attachment/2020/04/12/img-8873.JPG&quot; width=&quot;1168&quot; /&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Siapa orang yang memfoto kegiatan tersebut?&lt;/p&gt;",
          multichoice: {
            id: 2049265,
            content:
              "&lt;p&gt;Perhatikan gambar berikut dengan seksama!&lt;/p&gt;\r\n\r\n&lt;p&gt;&lt;img alt=&quot;&quot; height=&quot;672&quot; src=&quot;https://soedirman.storage.edulogy.id/file/school/exam/question/attachment/2020/04/12/img-8873.JPG&quot; width=&quot;1168&quot; /&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Siapa orang yang memfoto kegiatan tersebut?&lt;/p&gt;",
            answers: [
              {
                id: 9402504,
                content: "&lt;p&gt;Raka&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402505,
                content: "&lt;p&gt;Orang lain&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402506,
                content: "&lt;p&gt;Bukan orang&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402507,
                content: "&lt;p&gt;Rizky&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086091,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Lokasi kantor edulogy saat ini (12 April 2020, 17.36)&lt;/p&gt;",
          multichoice: {
            id: 2049266,
            content:
              "&lt;p&gt;Lokasi kantor edulogy saat ini (12 April 2020, 17.36)&lt;/p&gt;",
            answers: [
              {
                id: 9402508,
                content:
                  "&lt;p&gt;&amp;nbsp;&lt;/p&gt;\r\n\r\n&lt;p&gt;Perkantoran Gading Regency, Gading Utama Blok A1 No.11&lt;br /&gt;\r\nKota Bandung 40292&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 9402509,
                content:
                  "&lt;p&gt;Gedung perkantoran Capital Place Lantai 49, Jalan Gatot Subroto, Jakarta.&lt;br /&gt;\r\n&amp;nbsp;&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402510,
                content:
                  "&lt;p&gt;&amp;nbsp;Pasaraya Blok M Gedung B Lt. 6,&amp;nbsp;Jalan Iskandarsyah II No.7, RW. 2, Melawai, Kebayoran Baru, RT.3/RW.1, Melawai, Kby. Baru,&amp;nbsp;Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12160&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402511,
                content:
                  "&lt;p&gt;Jl. Jalan Saja No. 33, Jakarta, Indonesia&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086092,
          type: "multichoice",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content: "&lt;p&gt;Kota asal tempat lahirnya edulogy&lt;/p&gt;",
          multichoice: {
            id: 2049267,
            content: "&lt;p&gt;Kota asal tempat lahirnya edulogy&lt;/p&gt;",
            answers: [
              {
                id: 9402512,
                content: "&lt;p&gt;Medan&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402513,
                content: "&lt;p&gt;Jakarta&lt;/p&gt;",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 9402514,
                content: "&lt;p&gt;Bandung&lt;/p&gt;",
                status: "correct",
                formated_status: "Benar",
              },
            ],
            mark: null,
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Isian Singkat",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086093,
          type: "shortanswer",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content: "Sebutkan ibu kota Provinsi Jawa Barat!",
          attachments: [],
          discussions: [],
          answers: [
            {
              id: 1239,
              content: "BDG",
            },
            {
              id: 1240,
              content: "Bandung Lautan Api",
            },
            {
              id: 1241,
              content: "Paris Van Java",
            },
            {
              id: 1242,
              content: "Bandung",
            },
            {
              id: 1243,
              content: "Kota Kembang",
            },
          ],
          statements: [],
          mark: null,
        },
      ],
    },
    {
      id: 79196,
      title: "Pengetahuan Khusus",
      description: null,
      total_points: "62.00",
      total_gained_points: "0.00",
      total_questions: 7,
      total_corrects: 0,
      total_incorrects: 0,
      total_filleds: 0,
      total_empties: 7,
      questions: [
        {
          formated_type: "Benar Salah",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086094,
          type: "truefalse",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content: "&lt;p&gt;Edulogy berasal dari amerka?&lt;/p&gt;",
          truefalse: {
            id: 6694,
            content: "&lt;p&gt;Edulogy berasal dari amerka?&lt;/p&gt;",
            answer: {
              id: 6694,
              status: "false",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Pilihan Ganda Kompleks",
          formated_status: "-",
          formated_scoring_mode: "Wajib Benar Semua",
          formated_difficulty: "-",
          id: 2086095,
          type: "multianswer",
          status: null,
          total_gained_points: "0.00",
          point: "2.00",
          difficulty: null,
          scoring_mode: "allornothing",
          content: "Pilihlah nama provinsi di Indonesia",
          multianswer: {
            formated_scoring_mode: "Wajib Benar Semua",
            id: 501,
            content: "Pilihlah nama provinsi di Indonesia",
            scoring_mode: "allornothing",
            answers: [
              {
                id: 3040,
                content: "California",
                status: "incorrect",
                formated_status: "Salah",
              },
              {
                id: 3041,
                content: "Jawa Barat",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 3042,
                content: "Jambi",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 3043,
                content: "Jawa Timur",
                status: "correct",
                formated_status: "Benar",
              },
              {
                id: 3044,
                content: "Jepang",
                status: "incorrect",
                formated_status: "Salah",
              },
            ],
            marks: [],
          },
          attachments: [],
          discussions: [],
          answers: [],
          statements: [],
          marks: [],
        },
        {
          formated_type: "Benar Salah",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086096,
          type: "truefalse",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Edulogy berasal dari dua kata yakni &lt;strong&gt;edu &lt;/strong&gt;yakni&amp;nbsp;&lt;em&gt;education&lt;/em&gt;&amp;nbsp;yang artinya &lt;em&gt;pendidikan &lt;/em&gt;dan &lt;strong&gt;logy&amp;nbsp;&lt;/strong&gt;yang artinya&amp;nbsp;&lt;em&gt;paham/ilmu/pengetahuan.&lt;/em&gt;&lt;/p&gt;",
          truefalse: {
            id: 6695,
            content:
              "&lt;p&gt;Edulogy berasal dari dua kata yakni &lt;strong&gt;edu &lt;/strong&gt;yakni&amp;nbsp;&lt;em&gt;education&lt;/em&gt;&amp;nbsp;yang artinya &lt;em&gt;pendidikan &lt;/em&gt;dan &lt;strong&gt;logy&amp;nbsp;&lt;/strong&gt;yang artinya&amp;nbsp;&lt;em&gt;paham/ilmu/pengetahuan.&lt;/em&gt;&lt;/p&gt;",
            answer: {
              id: 6695,
              status: "true",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Esai",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086097,
          type: "essay",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;&lt;strong&gt;Sebutkan&lt;/strong&gt; minimal lima nama provinsi di Indonesia!&lt;/p&gt;",
          essay: {
            id: 29392,
            content:
              "&lt;p&gt;&lt;strong&gt;Sebutkan&lt;/strong&gt; minimal lima nama provinsi di Indonesia!&lt;/p&gt;",
            answer: {
              id: 29392,
              content:
                "&lt;p&gt;Jika siswa menjawab minimal 5 dari nama provinsi di Indonesia berikut maka jawaban dianggap benar, contoh: Jawa Barat, Jawa Tengah, Jawa Timur, Bali, dsb.&lt;/p&gt;",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Benar Salah",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086098,
          type: "truefalse",
          status: null,
          total_gained_points: "0.00",
          point: "15.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;&lt;img alt=&quot;&quot; src=&quot;https://lontar.id/wp-content/uploads/2019/01/Gojek.jpg&quot; /&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Benarkah edulogy sudah melakukan kerjasama dengan salah satu unicorn Indonesia, GO-JEK?&lt;/p&gt;",
          truefalse: {
            id: 6696,
            content:
              "&lt;p&gt;&lt;img alt=&quot;&quot; src=&quot;https://lontar.id/wp-content/uploads/2019/01/Gojek.jpg&quot; /&gt;&lt;/p&gt;\r\n\r\n&lt;p&gt;Benarkah edulogy sudah melakukan kerjasama dengan salah satu unicorn Indonesia, GO-JEK?&lt;/p&gt;",
            answer: {
              id: 6696,
              status: "true",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Benar Salah",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086099,
          type: "truefalse",
          status: null,
          total_gained_points: "0.00",
          point: "15.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Benarkah edulogy menggunakan layanan payment gateway?&lt;/p&gt;",
          truefalse: {
            id: 6697,
            content:
              "&lt;p&gt;Benarkah edulogy menggunakan layanan payment gateway?&lt;/p&gt;",
            answer: {
              id: 6697,
              status: "true",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
        {
          formated_type: "Benar Salah",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "-",
          id: 2086100,
          type: "truefalse",
          status: null,
          total_gained_points: "0.00",
          point: "15.00",
          difficulty: null,
          scoring_mode: null,
          content:
            "&lt;p&gt;Bukalah &lt;a href=&quot;https://www.youtube.com/channel/UCdOsw3TfLpCEr6xkP6Ngpjw/videos&quot;&gt;tautan berikut&lt;/a&gt;.&lt;/p&gt;\r\n\r\n&lt;p&gt;Benarkah tautan tersebut adalah tautan resmi edulogy?&lt;/p&gt;",
          truefalse: {
            id: 6698,
            content:
              "&lt;p&gt;Bukalah &lt;a href=&quot;https://www.youtube.com/channel/UCdOsw3TfLpCEr6xkP6Ngpjw/videos&quot;&gt;tautan berikut&lt;/a&gt;.&lt;/p&gt;\r\n\r\n&lt;p&gt;Benarkah tautan tersebut adalah tautan resmi edulogy?&lt;/p&gt;",
            answer: {
              id: 6698,
              status: "true",
            },
            mark: null,
          },
          attachments: [],
          discussions: [],
          statements: [],
          mark: null,
        },
      ],
    },
    {
      id: 79197,
      title: "bagian 3",
      description: "test",
      total_points: "30.00",
      total_gained_points: "0.00",
      total_questions: 4,
      total_corrects: 0,
      total_incorrects: 0,
      total_filleds: 0,
      total_empties: 4,
      questions: [
        {
          formated_type: "Menjodohkan",
          formated_status: "-",
          formated_scoring_mode: "Wajib Benar Semua",
          formated_difficulty: "Sulit",
          id: 2086101,
          type: "matching",
          status: null,
          total_gained_points: "0.00",
          point: "10.00",
          difficulty: "hard",
          scoring_mode: "allornothing",
          content:
            "&lt;p&gt;hubungan pernyataan 1 dan 2 dengan tepat !&lt;/p&gt;",
          multichoice: null,
          multianswer: null,
          essay: null,
          truefalse: null,
          attachments: [
            {
              formated_type: "Video",
              id: 7075,
              type: "video",
              video: {
                name: "file-example-mp4-480-1-5mg.mp4",
                extension: "mp4",
                size: "1570024",
                url:
                  "https://soedirman.storage.dev.edulogy.id/file/school/master/question/attachment/2021/01/22/file-example-mp4-480-1-5mg.mp4",
              },
            },
          ],
          discussions: [
            {
              id: 22,
              type: "text",
              formated_type: "Teks",
              title: null,
              content: "&lt;p&gt;jjjjjjjjjjjjjjjjjj&lt;/p&gt;",
            },
            {
              id: 23,
              type: "image",
              formated_type: "Gambar",
              name: "baseline-arrow-drop-down-black-24dp.png",
              extension: "png",
              size: 102,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/master/question/discussion/2021/01/22/baseline-arrow-drop-down-black-24dp.png",
            },
            {
              id: 24,
              type: "audio",
              formated_type: "Suara",
              name: "file-example-mp3-1mg.mp3",
              extension: "mp3",
              size: 1087849,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/master/question/discussion/2021/01/22/file-example-mp3-1mg.mp3",
            },
            {
              id: 25,
              type: "video",
              formated_type: "Video",
              name: "file-example-mp4-480-1-5mg.mp4",
              extension: "mp4",
              size: 1570024,
              url:
                "https://soedirman.storage.dev.edulogy.id/file/school/master/question/discussion/2021/01/22/file-example-mp4-480-1-5mg.mp4",
            },
          ],
          answers: [
            {
              id: 1244,
              content: "&lt;p&gt;Thailand&lt;/p&gt;",
            },
            {
              id: 1245,
              content: "&lt;p&gt;indonesia&lt;/p&gt;",
            },
            {
              id: 1246,
              content: "&lt;p&gt;filipina&lt;/p&gt;",
            },
          ],
          statements: [
            {
              id: 314,
              content: "&lt;p&gt;jakarta&lt;/p&gt;",
              student_se_sect_q_answer_id: 1245,
            },
            {
              id: 315,
              content: "&lt;p&gt;manila&lt;/p&gt;",
              student_se_sect_q_answer_id: 1246,
            },
          ],
          marks: [],
        },
        {
          formated_type: "Menjodohkan",
          formated_status: "-",
          formated_scoring_mode: "Wajib Benar Semua",
          formated_difficulty: "Mudah",
          id: 2086102,
          type: "matching",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: "easy",
          scoring_mode: "allornothing",
          content:
            "&lt;p&gt;Jodohkan bahasa pemrograman dengan frameworknya.&lt;/p&gt;",
          multichoice: null,
          multianswer: null,
          essay: null,
          truefalse: null,
          attachments: [],
          discussions: [],
          answers: [
            {
              id: 1247,
              content: "&lt;p&gt;Django&lt;/p&gt;",
            },
            {
              id: 1248,
              content: "&lt;p&gt;CodeIgniter&lt;/p&gt;",
            },
            {
              id: 1249,
              content: "&lt;p&gt;Express&lt;/p&gt;",
            },
            {
              id: 1250,
              content: "&lt;p&gt;Laravel&lt;/p&gt;",
            },
          ],
          statements: [
            {
              id: 316,
              content: "&lt;p&gt;PHP&lt;/p&gt;",
              student_se_sect_q_answer_id: 1248,
            },
            {
              id: 317,
              content: "&lt;p&gt;NodeJS&lt;/p&gt;",
              student_se_sect_q_answer_id: 1249,
            },
            {
              id: 318,
              content: "&lt;p&gt;PHP&lt;/p&gt;",
              student_se_sect_q_answer_id: 1250,
            },
          ],
          marks: [],
        },
        {
          formated_type: "Menjodohkan",
          formated_status: "-",
          formated_scoring_mode: "Wajib Benar Semua",
          formated_difficulty: "Mudah",
          id: 2086103,
          type: "matching",
          status: null,
          total_gained_points: "0.00",
          point: "5.00",
          difficulty: "easy",
          scoring_mode: "allornothing",
          content:
            "&lt;p&gt;Jodohkan CEO berikut dengan perusahaannnya&lt;/p&gt;",
          multichoice: null,
          multianswer: null,
          essay: null,
          truefalse: null,
          attachments: [],
          discussions: [],
          answers: [
            {
              id: 1251,
              content: "&lt;p&gt;Google&lt;/p&gt;",
            },
            {
              id: 1252,
              content: "&lt;p&gt;Tesla&lt;/p&gt;",
            },
            {
              id: 1253,
              content: "&lt;p&gt;Gojek&lt;/p&gt;",
            },
            {
              id: 1254,
              content: "&lt;p&gt;Microsoft&lt;/p&gt;",
            },
          ],
          statements: [
            {
              id: 319,
              content: "&lt;p&gt;Nadiem Makarim&lt;/p&gt;",
              student_se_sect_q_answer_id: 1253,
            },
            {
              id: 320,
              content: "&lt;p&gt;Bill Gates&lt;/p&gt;",
              student_se_sect_q_answer_id: 1254,
            },
          ],
          marks: [],
        },
        {
          formated_type: "Isian Singkat",
          formated_status: "-",
          formated_scoring_mode: "-",
          formated_difficulty: "Sedang",
          id: 2086104,
          type: "shortanswer",
          status: null,
          total_gained_points: "0.00",
          point: "10.00",
          difficulty: "medium",
          scoring_mode: null,
          content: "&lt;p&gt;jjjjjjjjjjjjjjjjjjjjjjjjjj&lt;/p&gt;",
          attachments: [],
          discussions: [],
          answers: [
            {
              id: 1255,
              content: "s a t u",
            },
            {
              id: 1256,
              content: "wahid",
            },
            {
              id: 1257,
              content: "siji",
            },
            {
              id: 1258,
              content: "one",
            },
            {
              id: 1259,
              content: "sati",
            },
            {
              id: 1260,
              content: "hiji",
            },
            {
              id: 1261,
              content: "uno",
            },
            {
              id: 1262,
              content: "satu",
            },
            {
              id: 1263,
              content: "1",
            },
          ],
          statements: [],
          mark: null,
        },
      ],
    },
  ],
};

const AKM_ANALYSIS_RESULT = {
  literation: [
    {
      label: "Literasi Membaca",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Literasi Menghitung",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Literasi Keseluhuran",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  summary: {
    total_corrects: "5.5",
    total_incorrects: "4.5",
    total_scores: 10,
  },
  difficulty_level: [
    {
      label: "Mudah",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Sedang",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Sulit",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  knowledge_dimension: [
    {
      label: "Fakta",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Konsep",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Prosedur",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  stimulus_context: [
    {
      label: "Personal",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Sosial Budaya",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Saintifik",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  cognitive_level: [
    {
      label: "C1",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "C2",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "C3",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "C4",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "C5",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "C6",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  question_type: [
    {
      label: "Pilihan Ganda",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Pilihan Ganda Kompleks",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Uraian",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Benar Salah",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Isian Singkat",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Menjodohkan",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  domain: [
    {
      label: "Aljabar",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Pengukuran dan Geometri",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
  sub_domain: [
    {
      label: "Persamaan dan Pertaksamaan",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
    {
      label: "Relasi dan Fungsi",
      total_corrects: 5,
      total_incorrects: 5,
      total_scores: 10,
    },
  ],
};

export default [
  createSuccessRest("/v1/monitoring-exam", EXAMS),
  createSuccessRest("/v1/monitoring-exam/:examId/member", MEMBERS),
  createSuccessRest("/v1/monitoring-exam-submission", SUBMISSIONS),
  createSuccessRest("/v1/monitoring-exam-submission/:submissionId", SUBMISSION),
  createSuccessRest("/v1/analysis-akm", AKM_ANALYSIS_RESULT),
];
