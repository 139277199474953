import { EXAM_TYPE_FETCHED, EXAM_TYPE_FETCH_FAILED } from "./type";
import masterAPI from "api/master";

export const examTypeFetched = (data) => ({
  type: EXAM_TYPE_FETCHED,
  data,
});
export const examTypeFetchFailed = () => ({
  type: EXAM_TYPE_FETCH_FAILED,
});

export const fetchAll = () => {
  return (dispatch, getState) => {
    const { master } = getState();

    if (master.examTypes.isFetched === false) {
      masterAPI
        .getExamTypes()
        .then((data) => dispatch(examTypeFetched(data)))
        .catch(() => dispatch(examTypeFetchFailed()));
    }
  };
};
