import { combineReducers } from "redux";
import auth from "./auth/reducer";
import page from "./page/reducer";
import master from "./master/reducer";
import examMonitoringSubmission from "./examMonitoringSubmission/reducer";
import akm from "./akm/reducer";

const appReducer = combineReducers({
  auth,
  page,
  master,
  examMonitoringSubmission,
  akm,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
