import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Link from "components/Link/Link";
import toArray from "domain/Integer/toArray";
import ReactPaginate from "react-paginate";

class Pagination extends React.Component {
  static propTypes = {
    // currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    maxVisiblePage: PropTypes.number,
    onSwitch: PropTypes.func,
  };

  handleSwitch = ({ selected: PageNumber }) => {
    this.props.onSwitch(PageNumber + 1);
  };

  render() {
    const { currentPage, initialPage, totalPage, maxVisiblePage } = this.props;
    return (
      <ReactPaginate
        initialPage={initialPage - 1}
        pageCount={totalPage}
        pageRangeDisplayed={maxVisiblePage}
        marginPagesDisplayed={1}
        onPageChange={this.handleSwitch}
        containerClassName={"pagination justify-content-center justify-content-md-start"}
        pageClassName={"page-item"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        breakClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        activeLinkClassName={"active"}
        hrefBuilder={() => "#"}
      />
    );
  }
}

export default Pagination;
