import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function ResetScroll() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // We assume if you REPLACE rather than PUSH then you don't need to set scroll to top
      if (action === "PUSH") {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return null;
}
