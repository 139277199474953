import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const PROFILE = {
  id: 1,
  email: "raka.suryaardi@gmail.com",
  name: "Raka Suryaardi Widjaja",
  photo:
    "https://dewantara.storage.dev.edulogy.id/file/student/photo/2020/12/01606877-dodol-garut.jpg",
  user_id: 17142,
  school: {
    id: 45,
    name: "[DEV] EDU OFFICE",
    logo: null,
  },
};

export default [
  rest.post(`${BASE_URL}/v1/profile`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: PROFILE,
      })
    );
  }),
];
