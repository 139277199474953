import React from "react";
import PropTypes from "prop-types";
import TableResponsive from "./TableResponsive";
import Table from "./Table";
import Pagination from "components/Pagination/Pagination";
import debounce from "lodash/debounce";
import InputSearch from "components/Form/InputSearch";

class Datatable extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func,
    onSwitchPage: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      currentPage: PropTypes.number.isRequired,
      initialPage: PropTypes.number,
      totalPage: PropTypes.number.isRequired,
      maxVisiblePage: PropTypes.number.isRequired,
    }),
  };

  static defaultProps = {
    onSearch: null,
  };

  constructor(props) {
    super(props);
    this.debounceFn = null;
  }

  handleSearch = (e) => {
    e.persist();
    if (!this.debounceFn) {
      this.debounceFn = debounce(
        () => {
          this.props.onSearch && this.props.onSearch(e.target.value);
        },
        500,
        { leading: false, trailing: true }
      );
    }
    this.debounceFn();
  };

  render() {
    const {
      pagination,
      onSwitchPage,
      onSearch,
      minHeight,
      ...props
    } = this.props;
    return (
      <>
        {onSearch && (
          <div className="mb-2">
            <InputSearch onChange={this.handleSearch} />
          </div>
        )}

        {pagination.position !== "bottom" && (
          <Pagination
            totalPage={pagination.totalPage}
            maxVisiblePage={pagination.maxVisiblePage}
            onSwitch={onSwitchPage}
          />
        )}
        <div
          className={
            "card card-bordered " +
            (pagination.position === "bottom" ? "mb-2" : "mt-2")
          }
          style={{ minHeight: minHeight || 0 }}
        >
          <TableResponsive>
            <Table {...props}>
              {/* <thead>
              <tr>
              <th className="text-nowrap">#</th>
              <th className="text-nowrap">WAKTU MULAI</th>
              <th className="text-nowrap">WAKTU SELESAI</th>
              <th className="text-nowrap">SISA WAKTU</th>
              <th className="text-nowrap">STATUS</th>
              <th className="text-nowrap">AKSI</th>
            </tr>
            </thead>
            <tbody></tbody> */}
            </Table>
          </TableResponsive>
        </div>
        {pagination.position === "bottom" && (
          <Pagination
            initialPage={pagination.initialPage || 1}
            totalPage={pagination.totalPage}
            maxVisiblePage={pagination.maxVisiblePage}
            onSwitch={onSwitchPage}
          />
        )}
      </>
    );
  }
}

export default Datatable;
