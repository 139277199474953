import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginFromLegacy } from "redux/auth/action";
import userAPI from "api/user";
import qs from "qs";

function parseQueryParams(queryParams) {
  return qs.parse(queryParams, {
    ignoreQueryPrefix: true,
  });
}

function isInvalid({
  goto = null,
  token_type = null,
  created_at = null,
  expired_at = null,
  access_token = null,
  refresh_token = null, // NOTE: currently not given by legacy
}) {
  return !goto || !token_type || !created_at || !expired_at || !access_token;
}

export default function AuthLegacy(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => !!state.auth.access_token);
  const params = parseQueryParams(useLocation().search);

  const [errorMsg, setErrorMsg] = useState(null);

  function handleError(message) {
    setErrorMsg(message);
    setTimeout(() => {
      history.replace("/");
    }, 2000);
  }

  useEffect(() => {
    setTimeout(() => {
      if (isInvalid(params)) {
        handleError(
          "Gagal melakukan autentikasi. Anda akan segera dialihkan."
        );
        return;
      }

      const {
        goto,
        token_type,
        created_at,
        expired_at,
        access_token,
        refresh_token,
      } = params;

      if (isAuthenticated) {
        history.replace(goto);
      } else {
        userAPI
          .getProfileByAccessToken({ access_token })
          .then((admin) => {
            dispatch(
              loginFromLegacy({
                token_type,
                created_at,
                expired_at,
                access_token,
                refresh_token,
                admin,
              })
            );
            history.replace(params.goto);
          })
          .catch((err) => {
            handleError(
              "Gagal melakukan autentikasi. Anda akan segera dialihkan."
            );
          });
      }
    }, 1000);
  }, []);

  return (
    <div>
      <p className="ml-2 mt-2 mb-2 ff-mono text-dark">Mohon menunggu, permintaan Anda sedang diproses...</p>
      <p className="ml-2 ff-mono text-dark">{errorMsg}</p>
    </div>
  );
}

AuthLegacy.propTypes = {};
