import React from "react";
import { useHistory } from "react-router-dom";
import Button from "components/atoms/Button/Button";

export default React.forwardRef(
  ({ to, target, children, onClick, className = "", ...props }, ref) => {
    const history = useHistory();
    function handleClick() {
      // TODO: need to refactor this if's hell
      if (to) {
        if (to.startsWith("/")) {
          if (target) {
            window.open(window.location.origin + to, target);
          } else {
            history.push(to);
          }
        } else if (to.startsWith("http")) {
          window.open(to, target);
        }
      }
      onClick && onClick();
    }

    return (
      <Button ref={ref} className={className} onClick={handleClick} {...props}>
        {children}
      </Button>
    );
  }
);
