import React from "react";
import PropTypes from "prop-types";
import Select from "components/Form/ReactSelectWrapper";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import examMonitoringAPI from "api/exam/monitoring";
import { connect } from "react-redux";

class SchoolFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      schools: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      examMonitoringAPI
        .getMonitoringSchools({
          limit: 10000,
          offset: 0,
        })
        .then((data) =>
          this.setState({
            isLoading: false,
            schools: data.map((school) => ({
              value: school.id,
              label: school.name,
            })),
          })
        );
    }, 300);
  }

  onChange = (raw) => {
    if (!raw) raw = [];
    const schoolIds = raw.map(({ value }) => value);
    this.props.onChange(schoolIds);
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <SpinnerGrow variant="warning" />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Select
          {...this.props}
          isMulti
          placeholder="Semua Penyelenggara"
          options={this.state.schools}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSchoolId: state.auth.admin.school.id,
});

export default connect(mapStateToProps)(SchoolFilter);
