import {
  RESET,
  TYPE_CHANGED,
  CLASS_ADDED,
  CLASS_REMOVED,
  STUDENT_ADDED,
  STUDENT_REMOVED,
  EXAM_ADDED,
  EXAM_REMOVED,
  SWITCHED_TO_NEXT,
  SWITCHED_TO_PREV,
} from "./type";
import produce from "immer";

function resetStep(state, stepIndex) {
  switch (stepIndex) {
    case 0: {
      // nothing to reset
      break;
    }
    case 1: {
      state.classes = [];
      state.students = [];
      break;
    }
    case 2: {
      state.exams = [];
      break;
    }
  }
}

const TOTAL_STEP = 3;
const INITIAL_STATE = {
  currentStepIndex: 0,
  type: null,
  classes: [],
  students: [],
  exams: [],
};

export default produce((state, action = {}) => {
  switch (action.type) {
    case RESET: {
      return INITIAL_STATE;
    }
    case TYPE_CHANGED: {
      state.type = action.newType;
      return;
    }
    case CLASS_ADDED: {
      state.classes = [...state.classes, action.class];
      return;
    }
    case CLASS_REMOVED: {
      state.classes = state.classes.filter(({ id }) => id !== action.id);
      return;
    }
    case STUDENT_ADDED: {
      state.students = [...state.students, action.student];
      return;
    }
    case STUDENT_REMOVED: {
      state.students = state.students.filter(({ id }) => id !== action.id);
      return;
    }
    case EXAM_ADDED: {
      state.exams = [...state.exams, action.exam];
      return;
    }
    case EXAM_REMOVED: {
      state.exams = state.exams.filter(({ id }) => id !== action.id);
      return;
    }
    case SWITCHED_TO_NEXT: {
      const newIndex = state.currentStepIndex + 1;
      if (newIndex < TOTAL_STEP) {
        state.currentStepIndex = newIndex;
      }
      return;
    }
    case SWITCHED_TO_PREV: {
      const currentIndex = state.currentStepIndex;
      const newIndex = state.currentStepIndex - 1;

      if (newIndex >= 0) {
        resetStep(state, currentIndex);
        state.currentStepIndex = newIndex;
      }
      return;
    }
    default: {
      return state;
    }
  }
}, INITIAL_STATE);
