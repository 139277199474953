import React from "react";
import PropTypes from "prop-types";
import CardTab from "components/Tab/CardTab/CardTab";
import Table from "./Table";
import env from "domain/Environment/Env";
import Block from "components/atoms/Block/Block";

class index extends React.Component {
  render() {
    return (
      <>
        <Block>
          <CardTab
            renderInner={() => <h3 className="text-white">Ujian</h3>}
            renderTab={() => (
              // TODO: refactor CardTab agar tidak perlu begini
              <>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://sekolah.edulogy.id/school/master/question"
                        : "https://sekolah.dev.edulogy.id/school/master/question"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-book-read"></em>{" "}
                    <span>Bank Soal</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://sekolah.edulogy.id/school/exam"
                        : "https://sekolah.dev.edulogy.id/school/exam"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-notes-alt"></em>{" "}
                    <span>Ujian Sekolah</span>
                  </a>
                </li>
                <li className="active">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="text-azure"
                  >
                    <em className="icon ni ni-reports-alt"></em>{" "}
                    <span>Ujian Lembaga Lain</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://sekolah.edulogy.id/school/exam/package"
                        : "https://sekolah.dev.edulogy.id/school/exam/package"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-notes-alt"></em>{" "}
                    <span>Paket Ujian</span>
                  </a>
                </li>
              </>
            )}
          />
        </Block>

        <Block>
          <Table />
        </Block>
      </>
    );
  }
}

export default index;
