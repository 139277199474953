import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Datatable from "components/Table/Datatable";
import EmptyRow from "components/Table/EmptyRow";
import LoadingRow from "components/Table/LoadingRow";
import Row from "./Row";
import TypeFilter from "./Filter/TypeFilter";
import SchoolFilter from "./Filter/SchoolFilter";
import ReactTooltip from "react-tooltip";
import useFetchMonitoringExams from "hooks/useFetchMonitoringExams";
import { useHistory, useLocation } from "react-router-dom";
import Block from "components/atoms/Block/Block";
import qs from "qs";
import { castArrayToNumber } from "utils/array";

const PAGE_LIMIT = 10;
const MAX_VISIBLE_PAGE_NUMBER = 3;

function parseQueryParams(queryParams) {
  const { school_ids, types, current_page } = qs.parse(queryParams, {
    ignoreQueryPrefix: true,
  });
  return {
    school_ids: Array.isArray(school_ids)
      ? castArrayToNumber(school_ids)
      : null,
    types: Array.isArray(types) ? types : null,
    current_page: +current_page || 1,
  };
}

export default function Table(props) {
  const history = useHistory();
  const location = useLocation();
  const { school_ids, types, current_page } = parseQueryParams(location.search);

  const filters = useRef({
    school_ids,
    types,
    current_page,
    offset: (current_page - 1) * PAGE_LIMIT,
    limit: PAGE_LIMIT,
  });
  const { isFetched, data } = useFetchMonitoringExams(filters.current);

  function handleFilterChange(key, value) {
    history.replace({
      pathname: location.pathname,
      search:
        "?" +
        qs.stringify({
          school_ids,
          types,
          current_page: 1,
          [key]: value || null,
        }),
    });
  }

  function handleSwitchPage(pageNumber) {
    if (pageNumber === current_page) return;
    history.replace({
      pathname: location.pathname,
      search:
        "?" + qs.stringify({ school_ids, types, current_page: pageNumber }),
    });
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Block className="mt-3">
        <div className="row gy-2">
          <div className="col-4">
            <SchoolFilter
              defaultValue={school_ids || []}
              onChange={(schoolIds) =>
                handleFilterChange("school_ids", schoolIds)
              }
            />
          </div>
          <div className="col-3">
            <TypeFilter
              defaultValue={types || []}
              onChange={(types) => handleFilterChange("types", types)}
            />
          </div>
        </div>
      </Block>

      <Block className="pt-2">
        <Datatable
          compact
          onSwitchPage={handleSwitchPage}
          pagination={{
            currentPage: current_page,
            initialPage: current_page,
            totalPage: data.totalPage,
            maxVisiblePage: MAX_VISIBLE_PAGE_NUMBER,
            position: "bottom",
          }}
          style={{
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "382px" }} className="text-center">
                Ujian
              </th>
              <th style={{ width: "180px" }}>Jenis</th>
              <th style={{ width: "230px" }}>Waktu</th>
              <th style={{ width: "150px" }} className="text-center">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            {!school_ids ? (
              <EmptyRow
                colSpan={4}
                message={
                  <span className="text-base">Mohon pilih penyelenggara terlebih dahulu.</span>
                }
              />
            ) : (
              <>
                {!isFetched && <LoadingRow colSpan={4} />}
                {isFetched && data.exams.length === 0 && (
                  <EmptyRow colSpan={4} />
                )}
                {isFetched &&
                  data.exams.length > 0 &&
                  data.exams.map((exam) => <Row key={exam.id} exam={exam} />)}
              </>
            )}
          </tbody>
        </Datatable>
      </Block>
    </>
  );
}
