import auth from "./mockAPI/auth";
import user from "./mockAPI/user";
import master from "./mockAPI/master";
import school from "./mockAPI/school";
import exam from "./mockAPI/exam/exam";
import student from "./mockAPI/student";
import examMonitoring from "./mockAPI/exam/monitoring";

export const handlers = [
  ...auth,
  ...user,
  ...master,
  ...school,
  ...exam,
  ...student,
  ...examMonitoring,
];
