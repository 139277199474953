import { rest } from "msw";
import { removeTrailingChar } from "./string";

const BASE_URL = removeTrailingChar(process.env.REACT_APP_ENDPOINT, "/");
const SUCCESS_RESPONSE = {
  code: "200",
  status: "SUCCESS",
  message: "ok",
  data: {},
};
const INVALID_RESPONSE = {
  code: "400",
  status: "INVALID_DATA",
  message: "invalid data",
  error: [
    {
      message: "Invalid value",
      field: "username",
    },
  ],
};

export function createSuccessRest(path, data, options = {}) {
  return rest.get(BASE_URL + path, (req, res, ctx) => {
    return res(
      ctx.status(options.status || 200),
      ctx.delay(options.delay || null),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: data,
      })
    );
  });
}

export function createInvalidRest(path, error, options = {}) {
  return rest.get(BASE_URL + path, (req, res, ctx) => {
    return res(
      ctx.status(options.status || 200),
      ctx.delay(options.delay || null),
      ctx.json({
        ...INVALID_RESPONSE,
        error: error,
      })
    );
  });
}
