import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";
import { EXAM_TYPES } from "./masterData";

export default [
  rest.get(`${BASE_URL}/v1/master/exam-type`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: EXAM_TYPES,
      })
    );
  }),
];
