import { AUTH_LEGACY_LOGGED_IN, USER_LOGGED_IN, USER_LOGGED_OUT } from "./type";
import authAPI from "api/auth";

export const userLoggedIn = (auth) => ({
  type: USER_LOGGED_IN,
  auth,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const userLoggedInFromLegacy = (auth) => ({
  type: AUTH_LEGACY_LOGGED_IN,
  auth,
});

export const login = (credentials) => {
  return (dispatch) =>
    authAPI.login(credentials).then((auth) => {
      localStorage.setItem("auth", JSON.stringify(auth));
      dispatch(userLoggedIn(auth));
    });
};

export const logout = (accessToken) => {
  return (dispatch) =>
    authAPI.logout(accessToken).then(() => {
      localStorage.removeItem("auth");
      dispatch(userLoggedOut());
    });
};

export const loginFromLegacy = (auth) => {
  return (dispatch) => {
    localStorage.setItem("auth", JSON.stringify(auth));
    dispatch(userLoggedIn(auth));
  }
};
