import { createSuccessRest } from "utils/testing";

const SCHOOLS = [
  {
    id: 1,
    name: "SMA 27",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
  },
  {
    id: 2,
    name: "Edu Office",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
  },
];

const SCHOOL_YEARS = [
  {
    id: "1",
    name: "2018/2019",
    status: "inactive",
  },
  { id: "2", name: "2019/2020", status: "active" },
];

const GRADES = [
  { id: "X", name: "X" },
  { id: "XI", name: "XI" },
  { id: "XII", name: "XII" },
];

const CLASSES = {
  total_page: 1,
  class_years: [
    {
      id: 1024,
      school_class: {
        id: 764,
        name: "GBU",
        grade: "X",
      },
      school_year: {
        id: 1,
        name: "2018/2019",
      },
      teacher: {
        id: 3836,
        name: "Dendin Syihab",
      },
    },
    {
      id: 1025,
      school_class: {
        id: 765,
        name: "MAT",
        grade: "XI",
      },
      school_year: {
        id: 1,
        name: "2018/2019",
      },
      teacher: {
        id: 3836,
        name: "Dendin Syihab",
      },
    },
  ],
};

export default [
  createSuccessRest("/v1/school", SCHOOLS),
  createSuccessRest("/v1/school/year", SCHOOL_YEARS),
  createSuccessRest("/v1/school/grade", GRADES),
  createSuccessRest("/v1/school/class-year", CLASSES),
];
