import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export default [
  rest.post(`${BASE_URL}/v1/auth/login`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: {
          auth: {
            token_type: "bearer",
            created_at: "2021-02-02T04:57:27.506Z",
            expired_at: "2022-02-01T17:00:00.000Z",
            access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
            admin: {
              id: 1,
              email: "raka.suryaardi@gmail.com",
              name: "Raka Suryaardi Widjaja",
              photo:
                "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
              user_id: 17142,
              school: {
                id: 45,
                name: "[DEV] EDU OFFICE",
                logo: null,
              },
            },
          },
        },
      })
    );
  }),
];
