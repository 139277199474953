import Log from "domain/Log/Log";
import store from "redux/store";
import StackTrace from "stacktrace-js";
import { isErrorMessageOnBlacklist } from "./BlackList";

export const ErrorMessageReport = (message) => {
  const loggedInStudentId = store.getState().auth.student
    ? store.getState().auth.student.id
    : "<Guest>";

  const userAgent =
    navigator && navigator.userAgent
      ? navigator.userAgent
      : "<Browser tidak mendukung navigator.userAgent>";

  Log(`
      [Student ID]: ${loggedInStudentId}
      [Accessed URL]: ${window.location.href}
      [UserAgent]: ${userAgent}
      [Error Message]:
      ${message}
  `);
};

export const ErrorReport = (err, reactStack = null) => {
  if ((err instanceof Error) === false) return;

  if (isErrorMessageOnBlacklist(err.message)) return;

  StackTrace.fromError(err).then((stackframes) => {
    let stringifiedStack = stackframes.map((sf) => sf.toString()).join("\n");
    stringifiedStack = stringifiedStack || "<Tidak ada>";

    const errorName = err.name || "<Tidak ada>";
    const errorMessage = err.message || "<Tidak ada>";
    const errorStack = err.stack || "<Browser tidak mendukung stack trace>";
    const userAgent =
      navigator && navigator.userAgent
        ? navigator.userAgent
        : "<Browser tidak mendukung navigator.userAgent>";

    const loggedInStudentId = store.getState().auth.student
      ? store.getState().auth.student.id
      : "<Guest>";

    Log(`
      [Student ID]: ${loggedInStudentId}
      [Accessed URL]: ${window.location.href}
      [UserAgent]: ${userAgent}
      [Error Name]: ${errorName}
      [Error Message]: ${errorMessage}
      [StackTrace.js Stack Trace]:
      ${stringifiedStack}
      [JS Stack Trace]:
      ${errorStack}
  `);
  });
};
