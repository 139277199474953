export function removeLeadingChar(str, char) {
  if (str.startsWith(char)) {
    return str.slice(1, str.length);
  }
  return str;
}

export function removeTrailingChar(str, char) {
  if (str.endsWith(char)) {
    return str.slice(0, -1);
  }
  return str;
}
