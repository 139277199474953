import axios from "configuredAxios";

export default {
  get: ({ limit, offset, school_ids = null, types = null }) => {
    return axios
      .get("v1/monitoring-exam", {
        params: { school_ids, types, limit, offset },
      })
      .then((res) => res.data.data);
  },
  getMonitoringSchools: ({ limit, offset }) => {
    return axios
      .get("v1/monitoring-exam-school", {
        params: { limit, offset },
      })
      .then((res) => res.data.data);
  },
  getExam: ({ examId }) => {
    return axios
      .get(`v1/monitoring-exam/${examId}/`)
      .then((res) => res.data.data);
  },
  getMembers: ({ examId, school_class_year_ids }) => {
    return axios
      .get(`v1/monitoring-exam/${examId}/member/`, {
        params: { school_class_year_ids },
      })
      .then((res) => res.data.data);
  },
  getSubmissions: ({ exam_id, student_id, limit, offset }) => {
    return axios
      .get(`v1/monitoring-exam-submission`, {
        params: {
          exam_id,
          student_id,
          limit,
          offset,
        },
      })
      .then((res) => res.data.data);
  },
  getSubmission: ({ submissionId }) => {
    return axios
      .get(`v1/monitoring-exam-submission/${submissionId}`)
      .then((res) => res.data.data);
  },
  AKMAnalysis: {
    result: ({
      school_class_year_ids = null,
      student_ids = null,
      exam_ids,
    }) => {
      if (school_class_year_ids && student_ids) {
        throw new Error("Invalid params: both params cannot passed together");
      }
      return axios
        .get(`v1/analysis-akm`, {
          params: {
            school_class_year_ids,
            student_ids,
            exam_ids,
          },
        })
        .then((res) => res.data.data);
    },
    getExams: ({ limit, offset, school_ids, types = null }) => {
      return axios
        .get(`v1/analysis-akm-exam`, {
          params: {
            school_ids,
            types,
            limit,
            offset,
          },
        })
        .then((res) => res.data.data);
    },
  },
};
