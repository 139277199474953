import { useEffect, useState, useRef } from "react";
import examMonitoringAPI from "api/exam/monitoring";

const INITIAL_STATE = {
  isFetched: false,
  items: {
    totalPage: 1,
    exams: [],
  },
};

export default function useFetchMonitoringExams({
  school_ids = null,
  types = null,
  limit,
  offset,
}) {
  const [data, setData] = useState(INITIAL_STATE);

  const axiosCancel = useRef(null);
  useEffect(() => {
    if (axiosCancel.current === "function") {
      axiosCancel.current();
    }
    setData(INITIAL_STATE);
    examMonitoringAPI
      .get({
        school_ids,
        types,
        limit,
        offset,
        cancel: (cancel) => (axiosCancel.current = cancel),
      })
      .then((data) => {
        setData({
          isFetched: true,
          items: {
            totalPage: data.total_pages,
            exams: data.exams.map((exam) => ({
              id: exam.id,
              title: exam.title,
              start_at: exam.start_at,
              finish_at: exam.finish_at,
              school: {
                id: exam.school.id,
                name: exam.school.name,
                logo: exam.school.logo,
              },
              type: exam.type,
              formated_type: exam.formated_type,
            })),
          },
        });
      });
  }, [school_ids, types, limit, offset]);

  return {
    isFetched: data.isFetched,
    data: data.items,
  };
}
