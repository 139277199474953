import { createSuccessRest } from "utils/testing";

const STUDENTS = {
  total_page: 1,
  students: [
    {
      id: 1024,
      name: "Faisal",
      photo: "http://weh.jpg",
    },
  ],
};
export default [createSuccessRest("/v1/student", STUDENTS)];
